import * as React from "react"
import { Global } from "@emotion/react"
import CssBaseline from "@mui/material/CssBaseline"
import Typography from "@mui/material/Typography"
import SwipeableDrawer from "@mui/material/SwipeableDrawer"
import gldf_logo from "../../../img/gldf_logo.png"
import cube_image from "../../../img/cube_image.png"
import MenuContentMobile from "./MenuContentMobile"
import { Puller, StyledBox, Root, drawerBleeding } from "../../../utils/consts"

export default function SwipeableEdgeDrawer(props) {

  const { window } = props
  const [open, setOpen] = React.useState(false)

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen)
  }

  // This is used only for the example
  const container = window !== undefined ? () => window().document.body : undefined

  return (
    <Root>
      <CssBaseline />
      <Global
        styles={{
          ".MuiDrawer-root > .MuiPaper-root": {
            height: `calc(50% - ${drawerBleeding}px)`,
            overflow: "visible"
          }
        }}
      />
      <SwipeableDrawer
        container={container}
        anchor="bottom"
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: true
        }}
      >
        <StyledBox
          sx={{
            position: "absolute",
            top: -drawerBleeding,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            visibility: "visible",
            right: 0,
            left: 0
          }}
        >
          <Puller />

          {props.selectedCategory &&
            <div style={
              {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start'
              }}>
              <img src={cube_image} className={"logo__cube"} />
              <Typography className={"logo__title"}>
                <span>
                  {props.selectedCategory}
                </span>
              </Typography>
              <Typography className={"logo__percentage"}>
                {props.values
                  && props.selectedCategory
                  && props.values[props.selectedCategory]
                  && props.values[props.selectedCategory].percentage &&
                  <span>
                    {props.values[props.selectedCategory].percentage} %
                  </span>
                }
              </Typography>

            </div>
          }

          {!props.selectedCategory &&
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <img src={gldf_logo} className={"logo__gldf"} />
              <Typography className={"logo__title"}>
                <span>
                  GLDF Checker
                </span>
              </Typography>
            </div>
          }

        </StyledBox>
        <StyledBox
          sx={{
            px: 2,
            pb: 2,
            height: "100%",
            overflow: "auto"
          }}
        >
          <MenuContentMobile
            data={props.data}
            loading={props.loading}
            loadingError={props.loadingError}
            isMobile={props.isMobile}
            gldfVersion={props.gldfVersion}
            values={props.values}
            selected={props.selected}
            resetHandler={props.resetHandler}
            applications={props.applications}
            uploadGldf={props.uploadGldf}
            handleOpen={props.handleOpen}
            applicationValues={props.applicationValues}
            assignApplication={props.assignApplication}
            selectedCategory={props.selectedCategory}
            selectedApplication={props.selectedApplication} />
        </StyledBox>
      </SwipeableDrawer>
    </Root>
  )
}
