function ApplicationsCheck(props) {

  return (
    <>
      {props.applications.map((application, index) => {
        return (

          <div className={`card__result ${props.isMobile ? 'm2' : ''}`} key={index} onClick={() => { props.assignApplication(application.type)}}>
            <h4 style={{ margin: "5px 5px" }}>

              <span>{application.type}</span>

              {props.applicationValues &&
                props.applicationValues[application.type] &&
                props.applicationValues[application.type].is_categorized &&
                <strong className={"category__false"}>
                  Yes
                </strong>
              }
              {props.applicationValues &&
                props.applicationValues[application.type] &&
                !props.applicationValues[application.type].is_categorized &&
                <strong className={"category__false"}>
                  No
                </strong>
              }
            </h4>
          </div>
        )
      })}
    </>
  )
}

export default ApplicationsCheck