import { a } from "@react-spring/three"
import { useSpring } from "@react-spring/core"

function FillBox(props) {

  const { position, scaleZ } = useSpring({
    scaleZ: props.active ? [props.percentage / 100] : [0.0000],
    position: props.active ? [0, props.index + ((props.percentage / 100) / 2), 2] : [0, props.index, 2],
    config: { duration: props.active ? 4000 : 2000, mass: 5, tension: 600, friction: 100, precision: 0.0001 },
  })

  // const scaleZ = spring.to([0, 1], [0.001, 1])

  if (props.percentage > 0 && props.percentage !== 100) {
    return (
      <a.group>
        <a.mesh
          scale-z={scaleZ}
          position={position}
          rotation={[-Math.PI / 2, 0, props.index * .35]}
        >
          <a.boxBufferGeometry attach="geometry" args={[.9, .9, .9]} />
          <a.meshStandardMaterial attach="material" transparent={true} color={"#2f5794"} opacity={.8} />
        </a.mesh>
      </a.group>
    )
  } else if (props.percentage === 100) {
    return (
      <a.group>
        <a.mesh
          scale-z={scaleZ}
          position={position}
          rotation={[-Math.PI / 2, 0, props.index * .35]}
        >
          <a.boxBufferGeometry attach="geometry" args={[.9, .9, .9]} />
          <a.meshStandardMaterial
            attach="material"
            transparent={false}
            color={"#005dd7"}
            opacity={.8}
          />
        </a.mesh>
      </a.group>
    )
  }
  else { return <></>}
}

export default FillBox