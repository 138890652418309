function ApplicationsBox(props) {
  return (
    <>
      {props.applicationValues[props.selectedApplication] && props.applicationValues[props.selectedApplication].missing_properties.length ?
        <>
          <div
            className={`card__result upload__subtitle card__box`}
            style={{ padding: "1rem" }}>

            <h3 className={"card__missing"}>
          <span>
            Missing for : </span> {props.selectedApplication}
            </h3> 
            <ul style={{ paddingLeft: '1.5rem' }}>
              {props.applicationValues[props.selectedApplication] &&
              props.applicationValues[props.selectedApplication].missing_properties.map((prop, index) => {
                return (
                  <li style={{ textTransform: "capitalize" }} key={index}>
                    {prop}
                  </li>
                )
              })}
            </ul>
          </div>
        </> : null
      }
    </>
  )
}

export default ApplicationsBox