import React, { Suspense, useState } from "react"
import ReactDOM from "react-dom"
import "./styles.css"
import { Scene } from "./Canvas"

function App() {
  return (
    <>
      <Suspense fallback={null}>
        <Scene />
      </Suspense>
    </>
  )
}

ReactDOM.render(<App />, document.getElementById("root"))
