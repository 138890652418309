// @ts-nocheck
import axios from "axios"
import { useState, useEffect } from "react"
import { baseURL, basicAuth } from "../utils/consts"
// Menu
import MenuContentDesktop from "./Menu/Desktop/MenuContentDesktop"
// Modal
import ShareModal from "./Menu/ShareModal"
// Menu Items
import SwipeableEdgeDrawer from "./Menu/Mobile/SwipeableEdgeDrawer"

function UI({
  values,
  isMobile,
  selectedCategory,
  setGlfdVersion,
  gldfVersion,
  setPercentages,
  setApplicationValues,
  resetHandler,
  applications,
  applicationValues,
  selected
}) {

  // Get ?calculation= in case of query param
  let params = (new URL(document.location)).searchParams
  let calculation = params.get("calculation")

  // Fetch logic
  useEffect(() => {
    if (calculation !== null || calculation === undefined) {
      fetchData().then(r => console.log())
    }
  }, [])

  const fetchData = async () => {
    const res = await axios.get(`https://gldf-checker.relux.com/api/calculations/${calculation}`)
    setData(res.data)
    setPercentages(res.data.groups)
    setApplicationValues(res.data.categories)
    setGlfdVersion(res.data.gldf_info.version)
  }

  const [file, setFile] = useState(null);
  const [data, setData] = useState(null);
  const [fileName, setFileName] = useState('');
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingError, setLoadingError] = useState(null);


  const [selectedApplication, setSelectedApplication] = useState(null);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const uploadGldfDrop = (acceptedFiles) => {
    const formData = new FormData();
    if (acceptedFiles) {
      formData.append("gldf_file", acceptedFiles);
      setFileName(acceptedFiles.name)
      setLoading(true);
      axios
        .post(baseURL, formData, {"Content-Type": "multipart/form-data"})
        .then(res => {
          setData(res.data);
          setPercentages(res.data.groups);
          setApplicationValues(res.data.categories);
          setGlfdVersion(res.data.gldf_info.version);
          setLoading(false);
        })
        .catch(
          function (error) {
            setLoading(false);
            setLoadingError(error);
            console.log('Axios catched this error: ', error);
            return Promise.reject(error);
          }
        )
    }
  }

  const uploadGldf = (callback, renderPriority) => {

    const selectedFile = document.getElementById("fileUpload").files[0];
    const formData = new FormData();

    if (selectedFile) {
      formData.append("gldf_file", selectedFile);
      setLoading(true);
      axios
        .post(baseURL, formData, {"Content-Type": "multipart/form-data"})
        .then(res => {
          setData(res.data);
          setPercentages(res.data.groups);
          setApplicationValues(res.data.categories);
          setGlfdVersion(res.data.gldf_info.version);
          setLoading(false);
        })
        .catch(
          function (error) {
            setLoading(false);
            setLoadingError(error);
            console.log('Axios catched this error: ', error);
            return Promise.reject(error);
          }
        )
    }
  }

  const assignApplication = (application) => {
    setSelectedApplication(application);
  }

  return (
    <>
      { data &&
        <ShareModal
        isMobile={isMobile}
        data={data}
        open={open}
        handleClose={handleClose}
      />}

      {isMobile ?
        <SwipeableEdgeDrawer
          data={data}
          loading={loading}
          loadingError={loadingError}
          isMobile={isMobile}
          gldfVersion={gldfVersion}
          values={values}
          selected={selected}
          resetHandler={resetHandler}
          applications={applications}
          uploadGldf={uploadGldf}
          handleOpen={handleOpen}
          applicationValues={applicationValues}
          assignApplication={assignApplication}
          selectedCategory={selectedCategory}
          selectedApplication={selectedApplication}
        />
        :
        <MenuContentDesktop
          fileName={fileName}
          isMobile={isMobile}
          data={data}
          loading={loading}
          loadingError={loadingError}
          gldfVersion={gldfVersion}
          values={values}
          selected={selected}
          resetHandler={resetHandler}
          applications={applications}
          uploadGldf={uploadGldfDrop}
          handleOpen={handleOpen}
          applicationValues={applicationValues}
          assignApplication={assignApplication}
          selectedCategory={selectedCategory}
          selectedApplication={selectedApplication}
          setData={setData}
          setPercentages={setPercentages}
          setApplicationValues={setApplicationValues}
          setGlfdVersion={setGlfdVersion}
          setLoadingError={setLoadingError}
          setLoading={setLoading}/>
      }
    </>
  )
}

export default UI
