import * as THREE from "three"
import { useFrame } from "@react-three/fiber"
import React, { useState, useEffect } from "react"


export function CameraAnimation(props) {

  const [spinner, setSpinner] = useState(true);
  const [started, setStarted] = useState(false)
  const vec = new THREE.Vector3()

  useEffect(() => {
    setStarted(true)
    setTimeout(() => setSpinner(false), 1000);
  })

  useFrame(state => {
    if (started) {
      state.camera.lookAt(-4, 3, 1);
      {
        props.isMobile ?
        state.camera.position.lerp(vec.set(8, 6, 0), .01)
        : state.camera.position.lerp(vec.set(8, 6, 0), .01)
      }
    }
    return null
  })
  return null
}