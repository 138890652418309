import { a } from "@react-spring/three"
import { useSpring } from "@react-spring/core"

function Box(props) {
  const { opacity } = useSpring({
    opacity: props.percentage === 0 ? 0.3 : props.active ? 0.35 : 1,
    config: { duration: 2000 }
  })
  return (
    <a.group
      onClick={() =>
        props.selected === props.index ?
          props.setSelectedBox(-1)
          : (props.setSelectedBox(props.index), props.setSelectedCategory(props.category))
      }>
      <a.mesh
        position={[0, props.index + .5, 2]}
        rotation={[-Math.PI / 2, 0, props.index * .35]}
      >
        <boxBufferGeometry attach="geometry" args={[1, 1, 1]} />
        <a.meshStandardMaterial
          attach="material"
          transparent={true}
          opacity={props.index === props.selected ? .4 : opacity}
          color={props.index === props.selected ? "#002b5f" : "white"}
          roughness={.2} />
      </a.mesh>
    </a.group>
  )
}

export default Box