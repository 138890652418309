import { a } from "@react-spring/three"
import { CycleRaycast, BakeShadows, useCursor, softShadows } from '@react-three/drei'


function Lights(color) {
  return (
    <>
      {/* Fill */}
      <ambientLight intensity={0.2} />
      <directionalLight
        castShadow
        rotation={[0, 0, 0]}
        position={[5, 10, 5]}
        intensity={3}
      />
      {/* Ground */}
      <mesh receiveShadow rotation-x={-Math.PI / 2} position={[0, -0.75, 0]}>
        <planeGeometry args={[20, 20]} />
        <shadowMaterial opacity={.8} />
      </mesh>
      {/* This freezes the shadow map, which is fast, but the model has to be static  */}
      {/*<BakeShadows />*/}
    </>
  )
}

export default Lights;