import React, { useState } from "react"
import ApplicationsCheck from "../ApplicationsCheck"
import ApplicationsBox from "../ApplicationsBox"
import PropertiesBox from "../PropertiesBox"
import Dropzone from 'react-dropzone';
import dragImage from '../../../img/drag-drop-icon-1.jpg'
import ShareIcon from '@mui/icons-material/Share';

const dropzoneStyleActive = {
  border : "5px solid green"
};


function MenuContentDesktop(props) {

  return (
    <>
      <div className={"upload"}>
        <div>
          {/*<img style={{ width: '10%', marginTop: '20px', marginLeft: 'auto'}} src={"https://gldf.io/img/logo.png"} />*/}
          <h1 className={"app_title"}>GLDF Checker (Beta)</h1>
          <div className={"infoBox upload__subtitle"}
               style={{ background: "rgba(204,204,204,0.59)", padding: "1rem", borderRadius: "5px" }}>
            With this free tool your can check data contained in your GLDF file.
            You can analyse the fill level of the main GLDF properties and compare
            what kind of application it supports.
          </div>
        </div>
        <div>
        <Dropzone
          activeStyle={dropzoneStyleActive}
          onDrop={acceptedFiles => {
          console.log(acceptedFiles);
          props.uploadGldf(acceptedFiles[0]);
          props.resetHandler();
        }}
        >
        {({getRootProps, getInputProps}) => (
          <section className={"upload__field"}>
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <div className={"flex__drag"}>
                <div>
                  <img src={dragImage} className={"logo__drag"} />
                </div>
                <div>
                  <p>
                    {props.fileName ?
                      <>
                        <b>
                          {props.fileName}
                        </b>
                        <br />
                        <br /></> : ""}
                    Drag and drop GLDF file here, or click to select.
                  </p>
                </div>
              </div>
            </div>
          </section>
        )}
      </Dropzone>
        </div>

        {props.data && (
          <div className={"upload__button-div"}>
            <div className={"upload__button_flex"}>
              <button
                className={"share__button"} onClick={props.handleOpen}>
                <div className={'flex__drag align__share'}>
                  <ShareIcon fontSize={"small"} />
                  <div style={{ marginLeft: '.8rem'}}>Share</div>
                </div>
              </button>
            </div>
          </div>
        )}

        <div className={"applications__res-div"}>
          <div className={"upload__subtitle"}>
            <div style={{ margin: "1rem 0" }}>
              <span
                style={{ fontWeight: "bolder", color: 'black' }}>
                GLDF version:</span>
              <strong style={{ color: "#002b5f", marginLeft: ".5rem" }}>
                {props.gldfVersion}
              </strong>
            </div>
            <div style={{ margin: "1rem 0" }}>
              Does this file comply with these minimum applications standards?
            </div>
          </div>
          <ApplicationsCheck
            isMobile={props.isMobile}
            applications={props.applications}
            applicationValues={props.applicationValues}
            assignApplication={props.assignApplication}
            selectedApplication={props.selectedApplication}
          />
        </div>

        {props.selectedApplication !== null && props.applicationValues && (
          <ApplicationsBox applicationValues={props.applicationValues} selectedApplication={props.selectedApplication} />
        )}
        {props.selected !== -1 && props.values && (
          <PropertiesBox values={props.values} selectedCategory={props.selectedCategory} />
        )}
        <div className={"upload__subtitle"}>
          You can find details about GLDF at
          <a href="https://www.gldf.io" style={{ margin: "0px 4px" }}>https://www.gldf.io</a>
          and get a list of all properties
          <a target="_blank"
             style={{ margin: "0px 4px" }}
             href="https://github.com/globallightingdata/files/raw/master/bim/bim_properties.xlsx">
            here (.xls).</a>
        </div>
      </div>
    </>
  )
}
export default MenuContentDesktop;
