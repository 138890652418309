import { useEffect, useRef } from "react"
import { useFrame } from "@react-three/fiber"
import { Text } from "@react-three/drei"
import * as THREE from "three"
import { isMobile } from "react-device-detect"


function Word(props) {
  const color = new THREE.Color()
  const fontProps = {
    fontSize: .300,
    fontWeight: "bolder",
    textAlign: "left",
    height: .5
  }
  const ref = useRef()
  const ref_2 = useRef()

  useEffect(() => { })

  useFrame(({ camera }) => {
    // Make text face the camera
    ref.current.quaternion.copy(camera.quaternion)
    ref_2.current.quaternion.copy(camera.quaternion)
    ref.current.material.transparent = true
    ref_2.current.material.opacity = 1
  })

  return (
    <>
      {!props.isMobile ?
        <Text
          anchorX="left"
          lineHeight={200}
          scale={.65}
          color={"black"}
          ref={ref}
          position={[0.25, props.index + .5, .75]} {...props} {...fontProps}>
          {" "}
          {(props.category && props.definition) ? props.category + ': ' + props.definition : ""}
          {(props.category && !props.definition) ? props.category : ''}
          {" "}
        </Text> :
        <Text
          anchorX="left"
          lineHeight={200}
          scale={.65}
          color={"black"}
          ref={ref}
          // position={[-2, props.index + .5, -1.2]}
          position={[0.25, props.index + .5, .75]}
          {...props}
          {...fontProps}>
          {" "}
          {props.category ? props.category : ""}
          {" "}
        </Text>
      }
      <Text
        anchorX="right"
        lineHeight={100}
        scale={.65}
        ref={ref_2}
        color={"black"}
        position={[1, props.index + .5, 2.55]}
        {...props}
        {...fontProps}>
        {props.category && !props.isMobile &&
          props.percentage !== 0 ?
          (props.percentage + "%")
          : ""}
      </Text>
    </>

  )
}

export default Word