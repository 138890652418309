import Modal from "@mui/material/Modal"
import Box from "@mui/material/Box"
import { InputAdornment, Link, TextField } from "@mui/material"
import Button from "@mui/material/Button"
import { useCopyToClipboard } from "usehooks-ts"
import { useState } from "react"


function ShareModal(props) {
  const style = {
    position: "absolute",
    top: "40%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: props.isMobile ? "80%" : "600px",
    bgcolor: "background.paper",
    borderRadius: "1rem",
    boxShadow: 24,
    p: 3
  }
  const [value, copy] = useCopyToClipboard()
  const [email, setEmail] = useState("")

  const setEmailValue = (e) => setEmail(e.target.value)

  const subject = "%5BGLDF%20Checker%5D%20Your%20GLDF%20File%20Results"

  const emailResults =
    `mailto:` + `${email}` +
    `?subject=` + `${subject}` +
    `&body=Hi%20${email}%2C%0A%0A` +
    `Please%20find%20below%20the%20link%20to%20the%20GLDF%20Checker%20Results%20of%20%0A%0A` +
    `https://gldf-checker.relux.com?calculation%3D${props.data ? props.data.calculation_id : ""}` +
    `%20%0A%0ABest Regards,`

  return (
    <>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <h4 style={{ marginTop: ".5rem", fontSize: "1.2rem", color: "#595959" }}>Share your GLDF Checker results with
            a direct link or mail</h4>
          <div className={"upload__subtitle"}
               style={{ borderBottom: ".5px solid grey", marginBottom: "1rem", paddingBottom: "1rem" }}>
            You've uploaded a new GLDF file. You can always access the state of your GLDF file by sharing this link to
            your colleagues <strong>or yourself</strong>.
          </div>
          {props.data && (
            <TextField
              sx={{ input: { color: "#a9a9a9", font: "revert" } }}
              value={`${window.location.origin}?calculation=${props.data.calculation_id}`}
              size="small"
              disabled
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button
                      size={"small"}
                      sx={{ marginRight: "-.8rem" }}
                      onClick={() => copy(`${window.location.origin}?calculation=${props.data.calculation_id}`)}
                      edge="end"
                      color="primary">
                      {value ? "copied" : "copy"}
                    </Button>
                  </InputAdornment>
                )
              }}
              fullWidth id="fullWidth" />
          )}
          <div className={"m2"}></div>
          {props.data && (
            <TextField
              sx={{ input: { color: "#a9a9a9", font: "revert" } }}
              placeholder="example@mail.com"
              onChange={() => setEmailValue(event)}
              size="small"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <a target={"_blank"} href={emailResults} style={{ padding: "0px" }}>
                      <Button
                        size={"small"}
                        color={"primary"}
                        sx={{ marginRight: "-.8rem" }}
                        edge="end">
                        Send
                      </Button>
                    </a>
                  </InputAdornment>
                )
              }}
              fullWidth id="fullWidth" />
          )}
          {value && (
            <div className={"upload__subtitle"}
                 style={{ background: "#080c8f40", marginBottom: "1rem", padding: "1rem" }}>
              Did you improve the data quality of your GLDF file? Make sure to upload it again.
            </div>
          )}
        </Box>
      </Modal>
    </>
  )
}

export default ShareModal
