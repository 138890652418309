import ApplicationsCheck from "../ApplicationsCheck"
import ApplicationsBox from "../ApplicationsBox"
import PropertiesBox from "../PropertiesBox"


function MenuContentMobile(props) {
console.log("first")

  "use strict"
    ; (function (document, window, index) {
      var inputs = document.querySelectorAll(".inputfile")
      Array.prototype.forEach.call(inputs, function (input) {
        var label = input.nextElementSibling,
          labelVal = label.innerHTML

        input.addEventListener("change", function (e) {
          var fileName = ""
          if (this.files && this.files.length > 1)
            fileName = (this.getAttribute("data-multiple-caption") || "").replace("{count}", this.files.length)
          else
            fileName = e.target.value.split("\\").pop()

          if (fileName)

            label.querySelector("span").innerHTML = fileName, console.log("There is a name")
          else
            label.innerHTML = labelVal
        })

        // Firefox bug fix
        input.addEventListener("focus", function () {
          input.classList.add("has-focus")
        })
        input.addEventListener("blur", function () {
          input.classList.remove("has-focus")
        })

      })
    }(document, window, 0))
  return (
    <>
      <div className={"menuContent__mobile"}>
        <div className={"upload__button-div__mobile"}>
          <div className="box">
            <input
              id="fileUpload"
              className="inputfile inputfile-6"
              data-multiple-caption="{count} files selected"
              onChange={props.resetHandler}
              type="file" 
            />
            <label htmlFor="fileUpload">
              <span></span>
              <strong>Choose file&hellip;</strong>
            </label>
          </div>

          <div style={{  margin: ".5rem 0 0 .5rem"}}>
            <span style={{  fontWeight: 'normal', color: 'black', marginRight: '1rem', fontSize: '12px' }}>
              GLDF Version
            </span>
            <span style={{ color: "#002b5f", marginLeft: ".5rem", marginTop: "1.6rem", fontSize: '12px' }}>
              {props.gldfVersion}
            </span>
          </div>

          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
            { !props.loading ?
              <strong className={"upload__button__mobile"} onClick={() => props.uploadGldf()}>
                Upload
              </strong> :
              <strong className={"upload__button__mobile"}>
                Loading...
              </strong>
            }
            {props.data && (
              <strong
                className={"share__button__mobile"} onClick={props.handleOpen}>
                Share
              </strong>
            )}
          </div>

        </div>

        {/* ToDo: Wrap it */}

        <div className={"applications__res-div"}>
          <div className={"upload__subtitle"}>
            <div style={{ margin: "1rem 0" }}>
              Does this file comply with these minimum applications standards?
            </div>
          </div>
          {props.applicationValues &&
            <ApplicationsCheck
              isMobile={props.isMobile}
              applications={props.applications}
              applicationValues={props.applicationValues}
              assignApplication={props.assignApplication}
              selectedApplication={props.selectedApplication}
            />
          }
        </div>

        {/* ToDO: Wrap it */}
        {/*<div className={"upload__subtitle"}>
          You can find details about GLDF at
          <a href="https://www.gldf.io" style={{ margin: "0px 4px" }}>https://www.gldf.io</a>
          and get a list of all properties
          <a target="_blank"
             style={{ margin: "0px 4px" }}
             href="https://github.com/globallightingdata/files/raw/master/bim/bim_properties.xlsx">
            here (.xls).</a>
        </div>*/}

        {props.selectedApplication !== null && props.applicationValues && (
          <ApplicationsBox applicationValues={props.applicationValues} selectedApplication={props.selectedApplication} />
        )}
        {props.selected !== -1 && props.applicationValues && (
          <PropertiesBox values={props.values} selectedCategory={props.selectedCategory} />
        )}
        <div style={{ padding: '2rem 0'}}/>
      </div>
    </>
  )
}

export default MenuContentMobile
