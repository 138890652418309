import "../src/styles.css"
import Word from "./components/3DScene/Word"
import { isMobile } from "react-device-detect"
import React, { useState } from "react"
import FillBox from "./components/3DScene/FillBox"
import { UI, Box, Lights } from "./components"
import { Canvas } from "@react-three/fiber"
import { categories, applications } from "./utils/consts"
import { CameraAnimation } from "./components/CameraAnimation"

export function Scene() {

  const [active, setActive] = useState(false)
  const [values, setValues] = useState({})
  const [applicationValues, setApplicationValues] = useState()
  const [gldfVersion, setGldfVersion] = useState()
  const [selected, setSelected] = useState(-1)
  const [selectedCategory, setSelectedCategory] = useState()

  function resetHandler() {
    setActive(false)
    setValues({
      "Electrical": 0,
      "Emergency": 0,
      "Marketing": 0,
      "Mechanical": 0,
      "Mounting & Accessory": 0,
      "Sensing device": 0,
      "Photometric": 0,
      "Operations & Maintenance": 0
    })
  }

  function setVersionGldf(data) {
    if (data) {
      setGldfVersion(data)
    } else {
      /* swallow */
    }
  }

  function setPercentages(data) {
    if (data) {
      setActive(true)
      setValues(data)
    } else {
      /* swallow */
    }
  }

  function setValuesApplications(data) {
    if (data) {
      setApplicationValues(data)
    } else {
    }
  }

  function setSelectedCat(data) {
    if (data) {
      setSelectedCategory(data)
    }
  }

  return (
    <React.StrictMode>
      <>
        <UI
          isMobile={isMobile}
          selected={selected}
          values={values}
          selectedCategory={selectedCategory}
          gldfVersion={gldfVersion}
          setGlfdVersion={setVersionGldf}
          setPercentages={setPercentages}
          applications={applications}
          setApplicationValues={setValuesApplications}
          applicationValues={applicationValues}
          resetHandler={resetHandler}
        />
        <Canvas
          shadows
          ortographic
          className={"canvas__wide"}
          camera={{
            position: !isMobile ? [0, 8, -15] : [12, 6, -4],
            fov: !isMobile ? 115 : 100,
            zoom: !isMobile ? 2 : 2
          }}
        >
          <CameraAnimation isMobile={isMobile} />
          <Lights color={"white"} />
          {categories.map(function (category, index) {
            return (
              <React.Fragment key={"b" + index}>
                {selected !== index ?
                  <Word
                    isMobile={isMobile}
                    percentage={values[category.category] ? Number(values[category.category].percentage) : 0}
                    active={active}
                    index={index}
                    category={category.category}
                  /> :
                  <Word
                    isMobile={isMobile}
                    percentage={values[category.category] ? Number(values[category.category].percentage) : 0}
                    active={active}
                    index={index}
                    category={category.category}
                    definition={category.definition}
                  />
                }
                <FillBox
                  index={index}
                  key={"fill" + index}
                  percentage={values[category.category] ? Number(values[category.category].percentage) : 0}
                  active={active}
                />
                <Box
                  selected={selected}
                  setSelectedBox={setSelected}
                  setSelectedCategory={setSelectedCat}
                  index={index} key={"b" + index}
                  category={category.category}
                  percentage={values[category.category] ? Number(values[category.category].percentage) : 0.0001}
                  active={active}
                />
              </React.Fragment>
            )
          })}
        </Canvas>
      </>
    </React.StrictMode>
  )
}