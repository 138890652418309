function PropertiesBox(props) {
  return (
    <>
      {props.values[props.selectedCategory] && props.values[props.selectedCategory].missing_properties.length ?
        <>
          <div className={"card__result upload__subtitle card__box"}
               style={{ padding: "1rem" }}>
            <h3 className={"card__missing"}>
            <span>
              Missing for : </span> {props.selectedCategory}
            </h3>
            <ul style={{ paddingLeft: '1.5rem' }}>
              {props.values[props.selectedCategory] &&
                props.values[props.selectedCategory].missing_properties.map((prop, index) => {
                  return (
                    <li style={{ textTransform: "capitalize" }} key={index}>
                      {prop}
                    </li>
                  )
                })}
            </ul>
          </div>
        </> : null
      }
    </>
  )
}

export default PropertiesBox

/*
*         {props.values[props.selectedCategory] && (
          <h3 className={"card__missing"}>
            <span>
              Missing for: </span> {props.selectedCategory}
          </h3>
        )}
* */