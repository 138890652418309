import { styled } from "@mui/material/styles"
import { grey } from "@mui/material/colors"
import Box from "@mui/material/Box"

const categories = [
  {
    category: "Operations & Maintenance",
    definition: "Lifetime, Quality, Health"
  },
  {
    category: "Marketing",
    definition: "GTIN, Product Information, Classifications, List prices, Text, Pictures"
  },
  {
    category: "Mounting & Accessory",
    definition: "Mounting type, Placements, Accessories, Measures"
  },
  {
    category: "Sensing device",
    definition: "Type of Sensor, Detections Range, Timing"
  },
  {
    category: "Photometric",
    definition: "LDC, Luminous Flux, Spectrum, CCT, CRI, Lamps, Classifications"
  },
  {
    category: "Emergency",
    definition: "Emergency LDC, Emergency Luminous Flux, Battery"
  },
  {
    category: "Electrical",
    definition: "Input power, Standby, Control Gear, IP, Connection"
  },
  {
    category: "Mechanical",
    definition: "Size, Shape, Material, Packaging, Logistics"
  },

]

const applications = [
  { type: "Architecture" },
  { type: "Commercial" },
  { type: "Electrical Design" },
  { type: "Lighting Design" },
  { type: "Maintenance" }
]

const baseURL = "https://gldf-checker.relux.com/api/files/check/"

const basicAuth = "Basic cmVsdXhuZXRfYXBpOm1kbGxrbHdxenplYnZhYXNkcmRqamg="

const drawerBleeding = 53

const Root = styled("div")(({ theme }) => ({
  height: "100%",
  backgroundColor:
    theme.palette.mode === "light" ? grey[100] : theme.palette.background.default
}))

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "light" ? grey[200] : grey[800]
}))

const Puller = styled(Box)(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: "#c1c1c1",
  borderRadius: 3,
  position: "absolute",
  top: 24,
  left: '85%'
}))


export { categories, applications, baseURL, basicAuth, drawerBleeding, Root, StyledBox, Puller }